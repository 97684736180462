<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :loading="loading"
          hide-default-footer
          :page.sync="page"
          :items-per-page="table.itemsPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Pembagian Sembako</v-toolbar-title>
              <!-- <v-divider class="mx-4" inset vertical></v-divider>
              <v-text-field
                v-model="search"
                @keyup.enter="searching()"
                append-icon="mdi-magnify"
                label="Cari"
                single-line
                hide-details
              ></v-text-field> -->
              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="1024px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialog()">
                    Tambah
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="2" md="2">
                          <label for="firstname">Harga sembako</label>
                        </v-col>
                        <v-col cols="2" md="2">
                          <v-text-field
                            label="Harga sembako"
                            type="number"
                            v-model="editedItem.harga_sembako"
                            outlined
                            dense
                            hide-details
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" md="3" style="font-weight: bold">
                          {{ new Intl.NumberFormat(['id']).format(editedItem.harga_sembako) }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">No</th>
                                  <th class="text-left">Anggota</th>
                                  <!-- <th class="text-left">Jenis Upah</th> -->
                                  <th class="text-left">Bagian</th>
                                  <th class="text-left">Wilayah</th>
                                  <th class="text-left">Program Tabungan</th>
                                  <th class="text-left">Aksi</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, index) in anggotas" :key="index">
                                  <td>
                                    {{ index + 1 }}
                                  </td>
                                  <td>
                                    {{ item.nama + ' (' + item.kode_anggota + ')' }}
                                  </td>
                                  <td>
                                    {{ item.divisi }}
                                  </td>
                                  <td>
                                    {{ item.wilayah }}
                                  </td>
                                  <td>
                                    {{ item.nama_jenis }}
                                  </td>
                                  <td>
                                    <v-checkbox
                                      v-model="editedItem.selected"
                                      label="Pilih"
                                      :value="item.id_tabungan"
                                    ></v-checkbox>
                                  </td>
                                </tr>
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colspan="5"></td>
                                  <td>
                                    <v-checkbox
                                      class="mt-4"
                                      v-model="editedItem.selectedAll"
                                      @click="selectAll()"
                                      label="Pilih Semua"
                                    ></v-checkbox>
                                    <v-btn class="mb-2" color="primary" :loading="loadingSimpan" @click="simpan()"
                                      >Simpan</v-btn
                                    >
                                  </td>
                                </tr>
                              </tfoot>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="close"> Batal </v-btn>
                    <!-- <v-btn color="primary" @click="save"> Simpan </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" @click="closeDelete">Batal</v-btn>
                    <v-btn color="secondary" @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-form class="my-4 ml-4">
              <v-row>
                <v-col cols="1" md="1">
                  <label for="firstname">Tahun</label>
                </v-col>
                <v-col cols="2" md="2">
                  <v-text-field
                    type="number"
                    hide-details
                    outlined
                    dense
                    v-model="table.tahun"
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" md="1">
                  <label for="firstname">Cari</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-text-field
                    label="Kode / nama anggota"
                    hide-details
                    outlined
                    dense
                    v-model="table.search"
                    @change="optionChange()"
                  ></v-text-field>
                </v-col>
                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-slot:item.anggota="{ item }">
            {{ item.nama_anggota + ' (' + item.kode_anggota + ')' }}
          </template>
          <template v-slot:item.besar_tabungan="{ item }">
            <div style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.besar_tabungan) }}</div>
          </template>
          <template v-slot:item.sisa_bersih_pendapatan="{ item }">
            <div style="text-align: right">{{ new Intl.NumberFormat(['id']).format(item.sisa_bersih_pendapatan) }}</div>
          </template>
          <template v-slot:item.aksi="{ item }">
            <!-- <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon> -->
          </template>
        </v-data-table>
        <div class="text-center py-4 pl-4">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil, mdiContentSave } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiContentSave,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Anggota', value: 'anggota', sortable: false },
      // { text: 'Jns Upah', value: 'jenis_upah', sortable: false },
      { text: 'Bagian', value: 'divisi', sortable: false },
      { text: 'Wilayah', value: 'wilayah', sortable: false },
      { text: 'Program Tabungan', value: 'nama_jenis', sortable: false },
      { text: 'Besar', value: 'besar_tabungan', sortable: false },
      { text: 'Waktu', value: 'created_at', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    desserts: [],
    loading: true,
    loadingSimpan: false,
    page: 1,
    table: {
      pageCount: 0,
      itemsPerPage: 25,
      search: '',
      tahun: new Date().toJSON().slice(0, 4),
      // tahun: 2022,
    },
    length_menus: [25, 50, 100, 500, 1000],

    anggotas: [],
    editedIndex: -1,
    editedItem: {
      harga_sembako: 0,
      selected: [],
      selectedAll: false,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Sembako' : 'Edit Sembako'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    page: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
    this.getDataFromApi()
  },

  methods: {
    optionChange() {
      this.page = 1
      this.getDataFromApi()
    },
    // initialize() {
    // },
    getDataFromApi() {
      this.loading = true
      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('search', this.table.search)
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('tahunsembako', this.table.tahun)
      axios
        .post(`${apiRoot}/api/Sembako/getTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    getAnggotas() {
      this.$store.dispatch('loading', true)
      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      // form.append('tahun', this.table.tahun)
      // form.append('page', this.page)
      // form.append('itemsPerPage', this.table.itemsPerPage)
      // form.append('tahun', this.table.tahun)
      axios
        .post(`${apiRoot}/api/Sembako/getAllAnggota`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.anggotas = response.data.data
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    openDialog() {
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
      this.getAnggotas()
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    simpan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('id_tabungans', this.editedItem.selected)
      form.append('besar_tabungan', this.editedItem.harga_sembako)
      axios
        .post(`${apiRoot}/api/Sembako/addItemKreditSembakoBatch`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.getDataFromApi()
            this.close()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    searching() {
      this.options.page = 1
      this.getDataFromApi()
    },
    selectAll() {
      this.editedItem.selected = []
      if (this.editedItem.selectedAll === true) {
        this.anggotas.forEach(item => {
          // console.log(item)
          this.editedItem.selected.push(item.id_tabungan)
        })
      } else {
        this.editedItem.selected = []
      }
    },
  },
}
</script>
